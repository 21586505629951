<template>
    <b-modal centered modal-class="create_link_model"
             no-close-on-backdrop
             id="create_link_model" hide-footer
             hide-header>
      <div class="flex pt-[0.75rem] justify-between">
        <p class="text-[0.875rem] pl-3.5 leading-[0.938rem] font-poppins text-[#3C4549] font-bold">Original Link</p>
        <div @click="$bvModal.hide('create_link_model'); resetLink()" class="float-right w-6 h-6 flex justify-end">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
          </svg>
        </div>
      </div>
      <div>
        <URLInputV2
            id="quick_link_store"
            class="!rounded-lg"
            v-model="getSaveLink.url"
            :validations="validations"
            @input="checkLinkPreviewWithCTA($event)"
            @onEnter="checkLinkPreviewWithCTA($event)"
            type="text"
            label="Enter URL of the webpage here"
            iconName="lock.svg"
            customClass="!h-[2.875rem] bg-white !shadow-xs border-0 rounded-[0.5rem]"
            :errorType="validations.url || validations.urlValid || validations.urlLength  ? 'danger' : ''"
          >
            <template v-slot:right_opt v-if="getSaveLink.url">
              <button type="button" @click="$refs.UTMModal.resetUTMValidations(); $bvModal.show('modal-create-utm-v2')" class="bg-white border-0 w-28 text font-semibold !text-blue-700">+ Add UTM</button>
            </template>
            <template v-slot:error_message>
              <InputFieldMessage v-if="validations.url" :message="messages.url"></InputFieldMessage>
              <InputFieldMessage v-else-if="validations.urlLength" :message="messages.urlLength"></InputFieldMessage>
              <InputFieldMessage v-else-if="validations.urlValid" :message="messages.urlValid"></InputFieldMessage>
              <InputFieldMessage v-else-if="linkFeaturesValidation.isUTMValid" message="Please enter valid UTMS"></InputFieldMessage>
            </template>
          </URLInputV2>
        <UTMComponentV2 ref="UTMModal" :isShowUTMDropdown="internalChecks.isShowUTMDropdown" ></UTMComponentV2>

        <div v-if="getSaveLink._id">
          <p class="text-[0.875rem] pl-3.5 pt-[1.25rem] leading-[0.938rem] font-poppins text-[#3C4549] font-bold">Link Created</p>
          <UpdateSlugV2></UpdateSlugV2>
          <CampaignSearchDropdown ref="linkCampaignDropdown" :metaFetchStatus="metaFetchStatus"></CampaignSearchDropdown>
          <LinkFeatures ref="link_features" :linkFeaturesValidation="linkFeaturesValidation"></LinkFeatures>
        </div>
      </div>
      <div class="text-center pt-[1.25rem] pb-2">
        <Button
          id="link-create-button"
          type="button"
          class="text-white bg-[#2560D7] hover:bg-blue-700"
          buttonClass="btn-lg"
          @click="store"
        >
          <template v-if="!internalChecks.loader" v-slot:label>{{ computeButtonText }}</template>
          <template v-if="internalChecks.loader" v-slot:loader>
            <Loader></Loader>
          </template>
        </Button>
      </div>
    </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { replugLinksValidation, URLMessages } from '@/common/attributes'
import { campaignTypes, linkTypes } from '@/state/modules/mutation-types'

export default {
  props: {
    isCallTypeDashboard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      validations: replugLinksValidation,
      messages: URLMessages,
      internalChecks: {
        isShowUTMDropdown: false,
        isAddUTM: false,
        loader: false
      },
      debounce: null,
      deepLinksBrandsList: [],
      metaFetchStatus: true,
      linkFeaturesValidation: {
        isUTMValid: false,
        isValidCampaignURL: false,
        isValidMeta: false,
        isValidFavicon: false,
        isValidPassword: false,
        isValidLinkExpiry: false,
        isValidABTesting: false,
        isValidDeepLink: false,
        isValidTrafficRouting: false
      }
    }
  },
  computed: {
    ...mapGetters(['getDefaultWorkspaceCampaign' ,'getWorkspace', 'getSaveLink', 'getUTMList', 'getSelectedUTM', 'getCreateUTM', 'getUTMList', 'getLinksLoaders']),
    computeButtonText() {
      return this.getSaveLink._id ? 'Save Shortened Link' : this.internalChecks.isShowUTMDropdown ? 'Apply UTM And Create' : 'Create'
    }
  },
  components: {
    CampaignSearchDropdown: () => import('@/views/pages/links/links-component/CampaignSearchDropdown.vue'),
    FloatingLabelInput: () => import('@/ui/ui-kit/v2/FloatingLabelInput.vue'),
    URLInputV2: () => import('@/ui/ui-kit/v2/URLInputV2.vue'),
    InputButton: () => import('@/ui/ui-kit/v2/InputButton.vue'),
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    UTMComponentV2: () => import('@/views/pages/links/links-component/UTMComponentV2.vue'),
    UpdateSlugV2: () => import('@/views/pages/links/links-component/UpdateSlugV2.vue'),
    LinkFeatures: () => import('@/views/pages/links/links-component/LinkFeatures.vue'),
  },
  async created () {
    this.resetLinkFeaturesValidation()
    await this.getCampaignByBrandId()
    await this.fetchCampaignsList({page: 1})
    await this.fetchWhitelabelAppSettings()
    this.$store.commit(linkTypes.SET_LINK_PREVIEW_URL, '')
    this.$store.commit(linkTypes.SET_LINK_PREVIEW_LOADER, false)
    this.$store.commit(linkTypes.SET_LINK_PREVIEW_ERROR, '')
    // get the link preview if the post is of type CTA. As if we are editing the link this does not happen.
    this.isTrialUserObj = this.isTrialUser(this.getSubscription)
    if (this.$route.query.shorten_url) {
      if (this.isTypeCTA(this.getSaveLink.call_to_action_id) && !this.isBridgeCTACampaign(this.getSaveLink.cta.cta_type)) this.linkPreview()
    }
    this.originalUrl = this.getSaveLink.shorten_url
    this.oldDomainObj = this.getSaveLink.domain
    this.deepLinksBrandsList = await this.showDeepLinksBrandsList()
    this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_EDIT_VALUE, this.getSaveLink.cta)
   },
  methods: {
    ...mapActions(['getCampaignByBrandId', 'fetchCampaignsList', 'fetchCustomizeLinkPreviewObj', 'showDeepLinksBrandsList', 'storeLink', 'createQRCode', 'setUTMPreviousId', 'fetchWhitelabelAppSettings']),
    /**
     * reset the link features validation
     */
    resetLinkFeaturesValidation() {
      this.linkFeaturesValidation = {
        isUTMValid: false,
          isValidCampaignURL: false,
          isValidMeta: false,
          isValidFavicon: false,
          isValidPassword: false,
          isValidLinkExpiry: false,
          isValidABTesting: false,
          isValidDeepLink: false,
          isValidTrafficRouting: false,
          isValidTags: false
      }
    },
    /**
     * This method is responsible for storing the link
     * @returns {Promise<void>}
     */
    async store() {
      this.resetLinkFeaturesValidation()

      // Added the utm parameters validation check
      // if(!this.getSaveLink._id) {
      //   this.linkFeaturesValidation.isUTMValid = !this.$refs.UTMModal.validateUTMS()
      //   if(!this.linkFeaturesValidation.isUTMValid) {
      //     return
      //   }
      // }

      if (!this.isValidURL(this.getSaveLink.url)) return

      if(this.internalChecks.loader) {
        return
      }

     if(this.getSaveLink._id) {

       // Validating the URL
       if(!this.$refs.linkCampaignDropdown.validateCampaignURL()) {
         return
       }

       if(this.getSaveLink.is_tags_enabled) {
         this.linkFeaturesValidation.isValidTags = !this.$refs.link_features.$refs.tags.validateTags()
       }

       if(this.getSaveLink.showSeo) {
          this.linkFeaturesValidation.isValidMeta = !this.$refs.link_features.$refs.customize_link_preview.validateMeta()
       }

       if(this.getSaveLink.showFavicon) {
         this.linkFeaturesValidation.isValidFavicon = !this.$refs.link_features.$refs.customize_favicon.validateFavicon()
       }

       if(this.getSaveLink.password_protected) {
         this.linkFeaturesValidation.isValidPassword = !this.$refs.link_features.$refs.password_protected.validatePassword()
       }

       if(this.getSaveLink.showLinkExpiry) {
         this.linkFeaturesValidation.isValidLinkExpiry = !this.$refs.link_features.$refs.link_expiry.validateLinkExpiry()
       }

       if (this.getSaveLink.is_routing_rule) {
         this.linkFeaturesValidation.isValidTrafficRouting = !(this.getSaveLink.routing_rules && this.getSaveLink.routing_rules.length)
       }

       if (this.getSaveLink.is_routing_rule && this.getSaveLink.routing_rules_edit) {
         this.getSaveLink.routing_rules = JSON.parse(JSON.stringify(this.getSaveLink.routing_rules.map(rule => {
           const blocks = rule.blocks.map(block => {
             return block.reduce((initialVal, currentVal) => {
               initialVal[currentVal.key] = currentVal.value.map(item => item.key)
               return initialVal
             }, {})
           })

           return {
             blocks,
             then: rule.then
           }
         })))
       }

       if(this.getSaveLink.is_ab_test) {
         this.linkFeaturesValidation.isValidABTesting = this.$refs.link_features.$refs.ab_testing.validateABTesting()
       }

       if(this.getSaveLink.is_deep_link_enabled) {
         this.linkFeaturesValidation.isValidDeepLink = !this.$refs.link_features.$refs.deep_linking.validateDeepLink()
       }

       if(!Object.keys(this.linkFeaturesValidation).every(k => this.linkFeaturesValidation[k] == false)) {
         return
       }

     }

     // if we are creating link first time then we set the default campaign
      if(!this.getSaveLink._id) {
        this.getSaveLink.brand_id = this.getWorkspace._id

        let defaultCampaign = this.getDefaultWorkspaceCampaign.find(item => item.is_default)
        if (defaultCampaign && defaultCampaign._id) {
          this.getSaveLink.call_to_action_id = defaultCampaign._id
        } else if (this.getDefaultWorkspaceCampaign && this.getDefaultWorkspaceCampaign.length) {
          this.getSaveLink.call_to_action_id = this.getDefaultWorkspaceCampaign[0]._id
        } else {
          this.$store.dispatch('toastNotification', {message: 'Please create your campaign first.', type: 'error'})
          return
        }
      }

      this.internalChecks.loader = true
      await this.storeLink(this.getSaveLink)
      if(this.isCallTypeDashboard) {
        this.$bvModal.show('create_link_model')
      }
      this.internalChecks.loader = false
    },
    resetLink() {
      this.$refs.UTMModal.resetUTMValidations()
      this.$store.commit('SET_LINKS_DEFAULT_VALUE')
      Object.keys(this.internalChecks).forEach(key => {
        this.internalChecks[key] = false;
      });
    },
    /**
     * computing the iframe error prompt
     * @returns {false|boolean|*}
     */
    computeIframeErrorPrompt() {
      return this.isFallbackCtaEnabled() && this.getLinks.previewErrorMessage === 'notAllowed' && this.isTypeCTA(this.getSaveLink.call_to_action_id) && !this.isBridgeCTACampaign()
    },
    async checkLinkPreviewWithCTA (event) {
      // check the call to action type, based on that, we will show the widget etc.

      this.getSaveLink.url = event
      this.setUTMPreviousId('')
      const isCTAType = this.isTypeCTA(this.getSaveLink.call_to_action_id)
      if (!isCTAType) {
        this.checkDeepLink(this.getSaveLink.url, this.deepLinksBrandsList)
      }
      if (this.getSaveLink.url && this.getSaveLink.url.length > 8) {
        this.getSaveLink.url = this.isCTAConcatenation(this.getSaveLink.url)
        if (isCTAType && !this.isBridgeCTACampaign()) await this.linkPreview()
      }

      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {

        // if fallback enabled and link not allowed for iframe
        if (this.computeIframeErrorPrompt() || !this.metaFetchStatus) {
          this.getSaveLink.showSeo = true
          this.getSaveLink.showFavicon = true
          this.setCustomizeLinkPreview()
          return
        }

        if (this.getSaveLink.showSeo) {
          this.setCustomizeLinkPreview()
          return
        }
      }, 600)
    },
    async setCustomizeLinkPreview () {
      let linkPreviewObj = await this.fetchCustomizeLinkPreviewObj({'url': this.getSaveLink.url})
      this.metaFetchStatus = linkPreviewObj.status

      if(!this.metaFetchStatus) {
        this.resetMetadata()
        return
      }

      this.getSaveLink.custom_favicon_url = linkPreviewObj.data.favicon_url ? linkPreviewObj.data.favicon_url : ''
      this.getSaveLink.seo.title = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.title : null
      this.getSaveLink.seo.description = linkPreviewObj.data.meta ? linkPreviewObj.data.meta.description : null
      if (linkPreviewObj.data.facebook.image && (linkPreviewObj.data.facebook.image.includes('http://') || linkPreviewObj.data.facebook.image.includes('https://'))) {
        this.getSaveLink.seo.image = linkPreviewObj.data.facebook.image
      } else {
        this.getSaveLink.seo.image = linkPreviewObj.data.images[0]
      }
    },
    /**
     * resetting the meta data if  link has no meta data
     */
    resetMetadata() {
      this.getSaveLink.custom_favicon_url =  ''
      this.getSaveLink.seo.title =  null
      this.getSaveLink.seo.description = null
      this.getSaveLink.seo.image = ''
    },
  },
  watch: {
    'getSaveLink.call_to_action_id' (value) {
      const isCTA = this.isTypeCTA(this.getSaveLink.call_to_action_id)
      if (!isCTA || this.isBridgeCTACampaign()) {
        return
      }
      this.getSaveLink.is_deep_link_enabled = false
      this.getSaveLink.is_deep_link_allowed = false
      this.linkPreview()
    },
    'getLinksLoaders.link_preview' (value) {

      if(this.getSaveLink.showSeo) {
        return;
      }

      // if fallback enabled and link not allowed for iframe
      if (this.computeIframeErrorPrompt() || !this.metaFetchStatus) {
        this.getSaveLink.showSeo = true
        this.getSaveLink.showFavicon = true
        this.setCustomizeLinkPreview()
        return
      }

      if (this.getSaveLink.showSeo) {
        this.setCustomizeLinkPreview()
        return
      }
    }
  }
}
</script>
